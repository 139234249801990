@use "../../global/" as g;

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: g.$base 0;

  &__item {
    margin: 0 calc(g.$base / 2);
  }

  &__link {
    color: black;

    &:hover {
      color: g.$secondary-1;
    }
  }
}
