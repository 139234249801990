@use "variables";

* {
  font-size: variables.$base;
}

html {
  width: 100vw;
  overflow-x: hidden;
  margin: 0 auto;
}

body {
  width: 100%;
  padding: 0 calc(variables.$base * 4);
  margin: 0 auto;
  box-sizing: border-box;
}

.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-padding {
  padding: 0;
}

.logo {
  width: 100%;
}

.MuiOutlinedInput-input {
  letter-spacing: normal !important;
}
