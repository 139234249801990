@use "../../global/" as global;

.user-management,
.avatar-management,
.background-management,
.emote-management,
.edit-user,
.edit-avatar,
.edit-background,
.edit-emote {
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);

  .avatars,
  .emotes,
  .backgrounds {
    border-radius: global.$border-radius;
    background-color: global.$light-1;
  }

  .user-list,
  .avatar-list,
  .background-list,
  .emote-list {
    table {
      border-radius: global.$border-radius;
      margin-top: global.$base;
      color: global.$dark-1;
      background-color: global.$light-1;
    }
  }
}

.hide-file-btn {
  padding: calc(global.$base / 3);
  &::-webkit-file-upload-button {
    display: none;
  }
}

.avatar-form {
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__section {
    border: solid 1px black;
    border-radius: 10px;
    padding: global.$base;
    margin: calc(global.$base / 2) 0;
    box-sizing: border-box;
    background-color: #fff;
  }

  &__asset-varient {
    margin: global.$base 0;
    box-shadow: 0 0 8px black;
    border-radius: 5px;
    padding: global.$base;
  }

  &__varient-frame {
    margin: calc(global.$base / 2) 0;
    border: solid 1px #333;
    border-radius: 5px;
    padding: global.$base;

    display: flex;
    flex-direction: row;
    @media screen and (max-width: global.$breakpoint-md) {
      flex-direction: column;
    }
  }

  &__variant-frame-section,
  &__attachment-point-section {
    box-sizing: border-box;
    padding: global.$base;
  }

  &__attachment-point {
    margin-bottom: global.$base;
  }

  .cmsm {
    margin: calc(global.$base / 2) 0;
  }
}
