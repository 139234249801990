@use "../../global" as global;

.authentication {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: calc(global.$base * 2) 0;
    width: 100%;
    img {
      width: 50%;
      @media (max-width: global.$breakpoint-sm) {
        width: 100%;
      }
    }

    span {
      margin: global.$base 0;
    }
  }

  hr {
    border-color: global.$primary-1;
    width: 100%;
    margin: calc(global.$base * 2) 0;
  }

  form {
    margin-top: 10vh;
    width: 20rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: global.$accent-1;
    padding: calc(global.$base * 2);
    box-sizing: border-box;
    border-radius: global.$border-radius;
    margin-bottom: 5vh;

    @media (max-width: global.$breakpoint-sm) {
      width: 100%;
    }
  }

  .register {
    display: flex;
    justify-content: center;
    margin-bottom: calc(global.$base * 5);

    form {
      margin-top: calc(global.$base * 2);
    }

    &__field {
      margin: calc(global.$base / 4) 0;
    }
  }
}
