@use "../../global/" as global;

.navigation {
  .image-wrapper {
    text-align: center;
    margin: calc(global.$base * 2) auto;

    img {
      width: 100%;
      // @media (max-width: global.$breakpoint-sm) {
      //   width: 100%;
      // }
    }
  }

  .logo {
    margin: global.$base auto;
  }

  .navbar {
    background-color: global.$accent-1 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: global.$accent-1;
    border-radius: global.$border-radius;
    padding: calc(global.$base / 2) global.$base;

    @media (max-width: global.$breakpoint-sm) {
      flex-direction: column;
    }

    button {
      margin: 0 global.$base;
    }
  }
}
