@use "../global/variables";

.logo {
  // margin: calc(variables.$base * 2) auto;
  width: 40%;

  &__NE {
    width: 40%;
    padding: variables.$base 0;
    @media screen and (max-width: variables.$breakpoint-sm) {
      width: 75%;
    }
  }

  &__image {
    width: 100%;
    height: 5vh;

    // @media screen and (max-width: variables.$breakpoint-xl) {
    //   // width: 33%;
    //   height: 33vh;
    //   @media screen and (max-width: variables.$breakpoint-lg) {
    //     // width: 50%;
    //     height: 50vh;
    @media screen and (max-width: variables.$breakpoint-sm) {
      width: 80%;
      height: auto;
    }
    //   }
    // }
  }

  &__subtitle {
    text-align: center;
    margin: variables.$base 0;

    span {
      font-size: 2rem;
      @media screen and (max-width: variables.$breakpoint-xl) {
        font-size: 1.5rem;
        @media screen and (max-width: variables.$breakpoint-lg) {
          font-size: 1rem;
          @media screen and (max-width: variables.$breakpoint-md) {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}

.logo__stacked {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
