/* Colors */
$primary-1: #fc683f;
$secondary-1: #6c8dff;
$accent-1: #e3e9f6;
$accent-2: #636b75;
$dark-1: #000000;
$light-1: #ffffff;

/* Sizing */
$base: 1rem;

/* Breakpoints */
$breakpoint-xs: 435px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

/* Border Radius */
$border-radius: 8px;
