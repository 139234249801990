@use "../global/variables";

.selectable-button {
  width: calc(100% - 2rem);
  height: 15rem;
  min-height: 6rem;
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin: 0.5rem 1rem;
  padding: 0;
  position: relative;

  &.selected {
    box-shadow: inset 0px 0px 0px 2px white;
  }

  span {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    background-color: variables.$primary-1;
    color: white;
    box-sizing: border-box;
    padding: 0.4rem;
    border-radius: 8px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
