@use "../../global/" as global;

.webcam {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  user-select: none;
  z-index: -1;

  &__video {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    user-select: none;

    video {
      object-fit: contain;
      opacity: 0;
      user-select: none;
    }
  }
}

.render-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;

  &__item {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transform-style: preserve-3d;
  }

  &__back {
    position: absolute;
  }
}

.display-window {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  &__hotkey-bar {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;

    .emote-emotes,
    .expression-emotes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      z-index: 1000;
      top: 0;
      left: 0;

      &__emote {
        border: solid 2px black;
        border-radius: global.$border-radius;
        width: calc(global.$base * 4);
        margin: calc(global.$base / 8);
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__hotkey {
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: global.$dark-1;
        border-radius: global.$border-radius;
        width: calc(global.$base);
        height: calc(global.$base);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }

    .watermark {
      z-index: 2000;
      top: 0;
      left: 0;
      display: flex;
      color: white;
      max-width: calc(global.$base * 16);
      height: auto;

      @media (max-width: global.$breakpoint-sm) {
        min-width: calc(global.$base * 8);
      }
    }
  }
}

.render-emote {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transform-style: preserve-3d;

  &__front,
  &__back {
    width: 100%;
    height: 100%;
    object-fit: contain; // TODO This might be taken from backend
    position: absolute;
  }
}

.microphone {
  &__allow {
    color: red;
    &--abs {
      z-index: 1000;
      position: absolute;
      width: 100vw;
      left: 0;
      bottom: 0;
      text-align: center;
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__audio-context {
    &--abs {
      position: absolute;
      bottom: global.$base;
      z-index: 1000;
      width: 100vw;
      text-align: center;
      left: 0;
    }
  }

  &__slider {
    width: 100%;
    position: relative;
  }

  &__ghost {
    position: absolute;
    width: 100%;
    height: 4px;
    top: 2.3rem;
    background-color: darken(global.$secondary-1, 10%);
    border-radius: global.$border-radius;
  }
}

.preload {
  position: absolute;
  user-select: none;
  img {
    user-select: none;
    opacity: 0;
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.account {
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);
}

.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.loading {
  position: absolute;
  z-index: 4000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc(global.$base * 3);
  background-color: global.$dark-1;

  &--done {
    opacity: 0;
    transition-duration: 3s;
    transition-delay: 2s;
  }
}

.dashboard {
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);
  padding-bottom: calc(global.$base * 4);

  .selectable-button {
    background-color: global.$secondary-1;
  }

  .user-component {
    background-color: global.$light-1;
    border-radius: global.$border-radius;
    margin-top: global.$base;
    min-height: calc(global.$base * 15);
    max-height: calc(global.$base * 20);

    &__image {
      width: 100%;
      height: auto;
      padding: calc(global.$base / 2);
      box-sizing: border-box;

      img {
        width: 100%;
        height: auto;
      }
    }

    .preview-image {
      padding: calc(global.$base / 2);
      box-sizing: border-box;
      aspect-ratio: 1/1;
    }
  }

  .preview {
    background-color: global.$light-1;
    margin-top: global.$base;
    padding: global.$base;
    border-radius: global.$border-radius;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__image {
      position: absolute;
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      z-index: 3;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    &__background {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
